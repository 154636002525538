export default {
  data () {
    return {
      snackbar: {
        show: null,
        timeout: 4000,
        color: '',
        msg: ''
      },
    }
  },
  methods: {
    showSnackbar (msg, color, closeCalback) {
      this.snackbar.msg = msg;
      this.snackbar.color = color;
      this.snackbar.show = true;
      this.resetSnack(closeCalback);
    },
    resetSnack (closeCalback) {
      setTimeout(() => {
        this.snackbar.show = false;
        if (closeCalback)
          closeCalback()
      }, this.snackbar.timeout + 1);
    },
    handleSnackbarSuccess (msg) {
      this.showSnackbar(msg, "success");
    },
    handleSnackbarError (err) {
      this.showSnackbar("Wystapił nieoczekiwany błąd.", "error");
    },
    showValidationError () {
      this.showSnackbar("Formularz zawiera błędy", "error");
      this.$nextTick(() => {
        const el = this.$el.querySelector(".v-messages.error--text:first-of-type");
        if (el) {
          this.$vuetify.goTo(el, {
            offset: 60
          });
        }
      });
    }
  }
}