import {
  mapGetters
} from 'vuex'
import { v4 as uuidv4 } from 'uuid';

export default {
  data () {
    return {
      tooltip: {
        textfield: "Wypełnij to pole",
        select: "Wybierz wartość z listy",
        checkbox: "Zaznacz/odznacz opcję",
        scroller: "Przesuń, aby zmienić opcje",
        clearFilter: "Kliknij, aby wyczyścić filtr danych",
        date: "Ustaw parametr raportu",
        uuid: null
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['isLogin']),
    isMobile () {
      return !this.$vuetify.breakpoint.mdAndUp;
    }
  },
  beforeCreate () {
    this.uuid = uuidv4();
  },
}