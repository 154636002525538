import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth-module.js"
import layout from "./layout-module.js"
import dict from "./dictionary-module"
import conf from "./configuration-module"



Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    layout,
    dict,
    conf
  }
});