let applicationServerPublicKey = process.env.VUE_APP_PWA_PUBLIC_KEY;

function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


export let getSubscription = (registration) => {
    return new Promise((resolve, reject) => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            registration.pushManager.getSubscription().then(subscription => {
                resolve(subscription);
            }).catch(e => {
                console.log(e);
                resolve(e);
            });

        } else
            reject("No support PushManager");

    });
};


export let subscribeUser = (registration) => {
    return new Promise((resolve, reject) => {
        const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
        registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey
            })
            .then(function (subscription) {
                let subJson = JSON.parse(JSON.stringify(subscription));

                var endpoint = subscription.endpoint;
                var p256dh = subscription.getKey('p256dh');
                var auth = subscription.getKey('auth');

                let sub = {
                    endpoint,
                    keys: {
                        p256dh,
                        auth
                    }
                };
                resolve(subJson);
            })
            .catch(function (err) {
                reject(('Failed to subscribe the user: ', err));
            });
    });

}

export let supportWebPush = () => {
    return 'Notification' in window && !!navigator.serviceWorker;
}