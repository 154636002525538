let secureBeforeEnter = (store) => {
    return (to, from, next) => {
        window.prevRouteName = from.name;
        store
            .dispatch("auth/init")
            .then(x => {
                if (!to.meta.requiresAuth) {
                    next();
                } else {
                    if (x) {
                        if (to.name != 'AccountUserProfile' && store.getters['auth/existNotAcceptedRegulations']) {
                            store.dispatch("auth/existNotAcceptedRegulations").then(y => {
                                if (y) {
                                    next({ name: "AccountUserProfile" })
                                } else {
                                    next();
                                }
                            })
                        }
                        else if (to.name.startsWith('AccountCkob')) {
                            if (store.getters["auth/isStakeholder"]) {
                                store.dispatch("auth/loadCkobRole").then(z => {
                                    if (z) {
                                        next();
                                    } else {
                                        next({ name: "AccountStart" })
                                    }
                                })
                            } else {
                                next();
                            }
                        } else {
                            next();
                        }
                    } else {
                        store.dispatch("auth/login");
                    }
                }
            })
            .catch(() => {
                next();
            });

    };
};

let getParamFromUrl = (name, url) => {
    if (!url) url = location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : results[1];
}


let axiosResponseInterceptor = (axios, store) => {
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            const originalRequest = error.config;
            if (
                error.response &&
                error.response.status === 401 &&
                !originalRequest._retry
            ) {
                store.dispatch("auth/removeSession");
                originalRequest._retry = true;
                return axios(originalRequest);
            } else {
                return Promise.reject(error);
            }
        }
    );
};

export {
    getParamFromUrl,
    secureBeforeEnter,
    axiosResponseInterceptor,

};
