const parseAllParamsToInt = (route) => {
    let params = Object.assign({}, route.params);
    Object.keys(params).forEach(key => {
        params[key] = Number.parseInt(params[key]);
    });
    return params;
}

export {
    parseAllParamsToInt
};