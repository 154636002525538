<template>
  <div class="text-center">
    <v-dialog max-width="700"
              v-model="snackbar">
      <v-card>
        <v-card-title>
          Powiadomienia
          <v-spacer />
          <v-btn icon
                 title="Zamknij dialog"
                 @click="snackbar = false">
            <v-icon :class="`${$vuetify.theme.dark ? 'white--text' : 'black--text'}`">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list-item link
                       @click="moveToNotification(item)"
                       v-for="item in items"
                       :key="item.messageId">
            <v-list-item-content>
              <v-row>
                <v-col cols="10">
                  <v-list-item-title class="text-wrap"
                                     :style="`color: ${$vuetify.theme.dark ? 'white' : 'black'} !important`"
                                     :title="item.details"
                                     v-text="item.title + ' (' + item.sentDate + ')'"></v-list-item-title>
                </v-col>
                <v-col cols="2"
                       class="pa-0">
                  <v-btn title="Usuń"
                         aria-label="Usuń"
                         :class="`${$vuetify.theme.dark ? 'white--text' : 'black--text'}`"
                         text
                         @click="removeItem(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-close</v-icon>
                    </v-list-item-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row justify="end"
                 class="ma-2 mb-2 mb-md-2">
            <v-btn text
                   :class="`${$vuetify.theme.dark ? 'white--text' : 'black--text'}`"
                   @click="snackbar = false">Zamknij</v-btn>
          </v-row>

        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    items: [],
  }),
  methods: {
    async removeItem (item) {
      this.items = this.items.filter((x) => x.messageId != item.messageId);
      this.$axios.put("/notification/receiving", item).then((response) => {
      }).catch(e => {
        this.$store.commit("layout/showErrorDialog", "Nie udało się zapisać danych")
      });
      if (this.items.length < 1) {
        this.snackbar = false;
      }
    },
    async moveToNotification (noty) {
      let id = noty.messageId
      let path = this.$route.path
      const navigationResult = await this.$router.push({ name: "AccountNotification", query: { id } });

      this.snackbar = false
    },
  },
  async created () {
    let { data } = await this.$axios.get("/notification/news");
    this.items = data;

    if (data.length > 0) this.snackbar = true;
    else this.snackbar = false;
  },
};
</script>
<style scoped>
.v-list-item__content {
  color: #000000 !important;
}
.theme--dark.v-subheader {
  color: #000000 !important;
}
.v-list-item__title {
  color: #000000 !important;
}
.v-subheader {
  font-size: 1.4rem !important;
}
</style>