import {
    axios
} from "@/util";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        dictionaries: {},
        dictionariesIsLazyLoading: {},
        details: [],
        categories: [],

    },
    mutations: {
        appendDictionaries (state, dictionaries) {
            let mergeDictionaries = Object.assign({}, state.dictionaries, dictionaries);
            state.dictionaries = mergeDictionaries;
        },
        setDetails (state, details) {
            state.details = details;
        },
        setCategories (state, categories) {
            state.categories = categories;
        },
        clearDictionaries (state) {
            state.dictionaries = {};
        },
        addEntry (state, {
            dictionaryCode,
            entry
        }) {
            let dictionary = state.dictionaries[dictionaryCode];
            dictionary.entries.push(entry);
        },
        updateEntry (state, {
            dictionaryCode,
            entry
        }) {
            let dictionary = state.dictionaries[dictionaryCode];
            let index = dictionary.entries.findIndex(e => e.dictionaryEntryCode == entry.dictionaryEntryCode);
            if (index > -1)
                Vue.set(dictionary.entries, index, entry);
        },
        updateDetails (state, {
            dictionaryCode,
            details
        }) {
            let index = state.details.findIndex(e => e.dictionaryCode == dictionaryCode);
            if (index > -1)
                Vue.set(state.details, index, details);
        },
        addDictionary(state,{details}){
                state.details.push(details);
        }
    },

    actions: {
        load ({
            commit
        }, {
            dictionaryCode: codes,
            archival
        }) {
            return axios.get(`/dictionaries`, {
                params: {
                    codes,
                    archival
                }
            }).then(({
                data
            }) => {
                let dicts = {};
                data.forEach(dict => {
                    dicts[dict.details.dictionaryCode] = dict;
                });
                commit("appendDictionaries", dicts);
                return dicts;
            });
        },
        lazyLoad ({
            dispatch,
            state,
        }, {
            dictionaryCode,
            archival
        }) {
            let dictionaryEntries = state.dictionaries[dictionaryCode];
            if (dictionaryEntries)
                return Promise.resolve(state.dictionaries[dictionaryCode]);
            else {
                let isLoading = state.dictionariesIsLazyLoading[dictionaryCode];
                if (isLoading)
                    return new Promise((resolve, reject) => {
                        let wait = setTimeout(() => {
                            clearTimeout(wait);
                            let dictionaryEntries = state.dictionaries[dictionaryCode];
                            if (dictionaryEntries)
                                return resolve(state.dictionaries[dictionaryCode]);
                            else {
                                state.dictionariesIsLazyLoading[dictionaryCode] = true;
                                dispatch("load", {
                                    dictionaryCode: [dictionaryCode],
                                    archival
                                }).then(dicts => {
                                    delete state.dictionariesIsLazyLoading[dictionaryCode]
                                    resolve(dicts[dictionaryCode]);
                                });
                            }
                        }, 500)
                    })

                state.dictionariesIsLazyLoading[dictionaryCode] = true;
                return dispatch("load", {
                    dictionaryCode: [dictionaryCode],
                    archival
                }).then(dicts => {
                    delete state.dictionariesIsLazyLoading[dictionaryCode]
                    return dicts[dictionaryCode];
                });
            }
        },

        loadDetails ({
            commit,
        }) {
            return axios.get('/dictionaries/details').then(({
                data
            }) => {
                commit("setDetails", data);
                return data;
            });
        },

        loadCategories ({
            commit,
        }) {
            return axios.get('/dictionaries/categories').then(({
                data
            }) => {
                commit("setCategories", data);
                return data;
            });
        }
    },


    getters: {
        getEntries: (state, getters) => dictionaryCode => {
            let dictionary = state.dictionaries[dictionaryCode];
            return !!dictionary ? dictionary.entries : [];
        },
        getEntry: (state, getters) => ({
            dictionaryCode,
            dictionaryEntryCode
        }) => {
            let dictionary = state.dictionaries[dictionaryCode];
            if (!dictionary)
                return null;
            return dictionary.entries.find(e => e.dictionaryEntryCode == dictionaryEntryCode);
        },
        getDeatailsByDictionaryCode: (state, getters) => dictionaryCode => {
            return state.details.find(d => d.dictionaryCode == dictionaryCode);
        },

        getDetailsByCategory: (state, getters) => categoryCode => {
            return state.details.filter(d => d.categoryCode == categoryCode);
        },
        getDictionaryType: (state, getters) => dictionaryCode => {
            let dictionary = state.dictionaries[dictionaryCode];
            return !!dictionary ? dictionary.type : 'simple';
        },
        getDictionaryValue: (state, getters) => dictionaryCode => {
            let dictionary = state.dictionaries[dictionaryCode];
            return !!dictionary ? dictionary.details.value : null;
        },

    }
};