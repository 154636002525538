<template>
  <v-main>
    <v-row no-gutters
           class="flex-column">
      <v-col cols="12"
             class="flex-shrink-0 flex-grow-0">
        <div :class="`pl-6 mx-0 py-2 py-xl-6  mb-5 primary text-center${$vuetify.theme.dark ? ' black--text' : ' white--text'}`"
             v-if="title">
          <h1 class="text-h6 text-md-h4"
              v-html="title" />
          <h2 v-if="subtitle"
              class="text-h6 text-md-h4"
              v-html="subtitle" />
          <v-icon v-if="showLockedIcon" title="Książka obiektu budowlanego jest zamknięta lub zarchiwizowana"
                  color="white">mdi-file-lock-outline</v-icon>
        </div>
      </v-col>
      <v-col cols="12">
        <v-container class="mb-2"
                     :fluid="fluid">
          <slot />
        </v-container>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: 'PageContainer',
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    fluid: { type: Boolean, default: false },
    showLockedIcon: {type: Boolean, default: false}
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    print () {
      window.print()
    }
  },
}
</script>
<style>
@media print {
  .no-print {
    display: none;
  }
  .content {
    padding: 0 !important;
  }
}
.v-main {
  min-height: calc(100vh - 227px);
}
</style>
