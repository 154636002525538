import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import {
  secureBeforeEnter
} from "../util/auth-util";
import {
  parseAllParamsToInt
} from "../util/route-util";

import store from "../store";
Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "Home",
  component: Home
},
{
  path: "/polityka-prywatnosci",
  name: "PrivacyPolicy",
  component: () =>
    import( /* webpackChunkName: "privacy" */ "../views/Page.vue"),
  props: {
    title: "Polityka prywatności",
    code: "privacy"
  }
},
{
  path: "/deklaracja-dostepnosci",
  name: "AccessibilityDeclaration",
  component: () =>
    import( /* webpackChunkName: "accessibility" */ "../views/Page.vue"),
  props: {
    title: "Deklaracja dostępności",
    code: "accessibility"
  }
},

{
  path: "/dowiedz-sie-wiecej",
  name: "More",
  component: () =>
    import( /* webpackChunkName: "more" */ "../views/Page.vue"),
  props: {
    title: "Dowiedz się więcej",
    code: "more"
  }

},
{
  path: "*",
  name: 'PageNotFound',
  component: () => import('@/views/PageNotFound')
},
{
  path: "/konto",
  name: "Account",
  component: () =>
    import( /* webpackChunkName: "account" */ "../views/Account.vue"),
  meta: {
    requiresAuth: true
  },
  children: [{
    path: '',
    name: 'OnLoginRedirect',
    component: () => import( /* webpackChunkName: 'onloginredirect' */ '@/views/OnLoginRedirect'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: 'szybki-start',
    name: 'AccountStart',
    component: () => import( /* webpackChunkName: 'accountstart' */ '@/views/AccountStart'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: 'rejestr-ckob',
    name: 'AccountCkobRegistry',
    component: () => import( /* webpackChunkName: 'accountckobregistry' */ '@/views/AccountCkobRegistry'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/metryka',
    name: 'AccountCkobTitlePage',
    component: () => import( /* webpackChunkName: 'accountckobtitlepage' */ '@/views/AccountCkobTitlePage'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: 'ksiazka-obiektu-budowlanego/:id/archiwum',
    name: 'AccountCkobTitlePageArchival',
    component: () => import( /* webpackChunkName: 'accountckobtitlepagearchival' */ '@/views/AccountCkobTitlePageArchival'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/interesariusze',
    name: 'AccountCkobStakeholders',
    component: () => import( /* webpackChunkName: 'accountckobstakeholders' */ '@/views/AccountCkobStakeholders'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/interesariusz/:stakeholderId',
    name: 'AccountCkobStakeholder',
    component: () => import( /* webpackChunkName: 'accountckobstakeholder' */ '@/views/AccountCkobStakeholder'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/nowy-interesariusz/:hash',
    name: 'AccountCkobNewStakeholder',
    component: () => import( /* webpackChunkName: 'accountckobnewstakeholder' */ '@/views/AccountCkobNewStakeholder'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/kandydat/:candidateId',
    name: 'AccountCkobNewCandidate',
    component: () => import( /* webpackChunkName: 'accountckobnewcandidate' */ '@/views/AccountCkobNewCandidate'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/dane-wz',
    name: 'AccountCkobWzsInfo',
    component: () => import( /* webpackChunkName: 'accountckobwzsinfo' */ '@/views/AccountCkobWzsInfo'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/dane-o-obiekcie',
    name: 'AccountCkobObjectInfo',
    component: () => import( /* webpackChunkName: 'accountckobobjectinfo' */ '@/views/AccountCkobObjectInfo'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/interesariusz',
    name: 'AccountCkobAddStakeholder',
    component: () => import( /* webpackChunkName: 'accountckobaddstakeholder' */ '@/views/AccountCkobAddStakeholder'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:id/przedstawiciel-organu-administracji',
    name: 'AccountCkobAddRepresentative',
    component: () => import( /* webpackChunkName: 'accountckobaddrepresentative' */ '@/views/AccountCkobAddRepresentative'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy',
    name: 'AccountCkobEntryRegistry',
    component: () => import( /* webpackChunkName: 'accountckobentriestypes' */ '@/views/AccountCkobEntryRegistry'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/decyzja-administracyjna',
    name: 'AccountCkobEntryAddAdminDecision',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddadmindecision' */ '@/views/AccountCkobEntryAddAdminDecision'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/decyzja-administracyjna/:entryId',
    name: 'AccountCkobEntryEditAdminDecision',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddadmindecision' */ '@/views/AccountCkobEntryAddAdminDecision'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/:type/:entryId/szczegoly',
    name: 'AccountCkobEntryDetail',
    component: () => import( /* webpackChunkName: 'accountckobentryeetail' */ '@/views/AccountCkobEntryDetail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/katastrofy-budowlane',
    name: 'AccountCkobEntryAddBuildingDisaster',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddbuildingdisaster' */ '@/views/AccountCkobEntryAddBuildingDisaster'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/katastrofy-budowlane/:entryId',
    name: 'AccountCkobEntryEditBuildingDisaster',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddbuildingdisaster' */ '@/views/AccountCkobEntryAddBuildingDisaster'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/ekspertyzy-i-opinie-techniczne',
    name: 'AccountCkobEntryAddTechExpertise',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddtechexpertise' */ '@/views/AccountCkobEntryAddTechExpertise'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/ekspertyzy-i-opinie-techniczne/:entryId',
    name: 'AccountCkobEntryEditTechExpertise',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddtechexpertise' */ '@/views/AccountCkobEntryAddTechExpertise'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/przeglady-techniczne',
    name: 'AccountCkobEntryAddFireMaintenance',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddfiremaintenance' */ '@/views/AccountCkobEntryAddFireMaintenance'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/przeglady-techniczne/:entryId',
    name: 'AccountCkobEntryEditFireMaintenance',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddfiremaintenance' */ '@/views/AccountCkobEntryAddFireMaintenance'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/roboty-budowlane',
    name: 'AccountCkobEntryAddBuildingWork',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddbuildingwork' */ '@/views/AccountCkobEntryAddBuildingWork'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/roboty-budowlane/:entryId',
    name: 'AccountCkobEntryEditBuildingWork',
    component: () => import( /* webpackChunkName: 'accountckobentriesaddbuildingwork' */ '@/views/AccountCkobEntryAddBuildingWork'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/kontrole-62',
    name: 'AccountCkobEntryAddPeriodicCheck',
    component: () => import( /* webpackChunkName: 'accountckobentryaddperiodiccheck' */ '@/views/AccountCkobEntryAddPeriodicCheck'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'ksiazka-obiektu-budowlanego/:ckobId/wpisy/kontrole-62/:entryId',
    name: 'AccountCkobEntryEditPeriodicCheck',
    component: () => import( /* webpackChunkName: 'accountckobentryaddperiodiccheck' */ '@/views/AccountCkobEntryAddPeriodicCheck'),
    props: parseAllParamsToInt,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: 'ksiazka-obiektu-budowlanego',
    name: 'AccountCkobAdd',
    component: () => import( /* webpackChunkName: 'accountckob' */ '@/views/AccountCkob'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'powiadomienia',
    name: 'AccountNotification',
    component: () => import( /* webpackChunkName: 'accountnotification' */ '@/views/AccountNotification'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'dane-uzytkownika',
    name: 'AccountUserProfile',
    component: () => import( /* webpackChunkName: 'accountuserprofile' */ '@/views/AccountUserProfile'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'admin/utworz-uzytkownika',
    name: 'Register',
    component: () => import( /* webpackChunkName: 'register' */ '@/views/AccountUserProfile'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'zmiana-hasla',
    name: 'AccountUserPassdChange',
    component: () => import( /* webpackChunkName: 'accountuserpassdchange' */ '@/views/AccountUserPassdChange'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: 'report',
    name: 'AccountReports',
    component: () => import( /* webpackChunkName: 'accountreports' */ '@/views/AccountReports'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: 'admin/email',
    name: 'AccountAdminDictionaryNbEmail',
    component: () => import( /* webpackChunkName: 'accountadmindictionarynbemail' */ '@/views/AccountAdminDictionaryNbEmail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'admin/api',
    name: 'AccountAdminApiUsers',
    component: () => import( /* webpackChunkName: 'accountadminapiusers' */ '@/views/AccountAdminApiUsers'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'admin/slowniki',
    name: 'AccountAdminDictionaryMgmt',
    component: () => import( /* webpackChunkName: 'accountadmindictionarymgmt' */ '@/views/AccountAdminDictionaryMgmt'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'admin/uprawnienia',
    name: 'AccountAdminPermisionMgmt',
    component: () => import( /* webpackChunkName: 'accountadminpermisionmgmt' */ '@/views/AccountAdminPermisionMgmt'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: 'admin/uprawnienia/grupy',
    name: 'AccountAdminPermisionMgmtRoleGroup',
    component: () => import( /* webpackChunkName: 'accountadminpermisionmgmtrolegroup' */ '@/views/AccountAdminPermisionMgmtRoleGroup'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'admin/uprawnienia/user',
    name: 'AccountAdminPermisionMgmtRoleUser',
    component: () => import( /* webpackChunkName: 'accountadminpermisionmgmtroleuser' */ '@/views/AccountAdminPermisionMgmtRoleUser'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: 'admin/role',
    name: 'AccountAdminRoleMgmt',
    component: () => import( /* webpackChunkName: 'accountadminrolemgmt' */ '@/views/AccountAdminRoleMgmt'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: 'admin/przerwa-techniczna',
    name: 'AccountAdminTechnicalBreak',
    component: () => import( /* webpackChunkName: 'accountadmintechnicalbreak' */ '@/views/AccountAdminTechnicalBreak'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'admin/narzedzia',
    name: 'AccountAdminTools',
    component: () => import( /* webpackChunkName: 'accountadmintools' */ '@/views/AccountAdminTools'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: 'admin/uzytkownicy',
    name: 'AccountAdminUserMgmt',
    component: () => import('@/views/AccountAdminUserMgmt'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: 'admin/audyt',
    name: 'AccountAdminAuditLog',
    component: () => import( /* webpackChunkName: 'accountadmintools' */ '@/views/AccountAdminAuditLog'),
    meta: {
      requiresAuth: true
    }
  },


  ],

}
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
});


router.beforeEach(secureBeforeEnter(store));

export default router;