import {
    axios
} from "@/util";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        configurations: [],
        userRoles: [],
    },
    mutations: {
        setConfigurations (state, configurations) {
            state.configurations = configurations;
        },
        updateConfiguration (state, configuration) {
            let index = state.configurations.findIndex(conf => configuration.configurationCode === conf.configurationCode);
            if (index > -1) {
                let configurationParam = state.configurations[index];
                let updatedConfiguration = Object.assign({}, configurationParam, configuration);
                Vue.set(state.configurations, index, updatedConfiguration);
            }
        },
        setUserRoles (state, userRoles) {
            state.userRoles = userRoles;
        }
    },
    actions: {
        load ({
            commit
        }, codes) {
            return axios.get(`/configurations`, {
                params: {
                    codes,
                }
            }).then(({
                data
            }) => {
                commit("setConfigurations", data);
                return data;
            });
        },
        loadUserRoles ({
            commit
        }) {
            return axios.get(`/users/stakeholder-roles`).then(({
                data
            }) => {
                commit("setUserRoles", data);
                return data;
            });
        },
        audit ({ }, {
            type
        }) {
            return axios.post(`/audits`, {
                type
            });
        },
    },
    getters: {
        getConfigurationValue: (state, getters) => configurationCode => {
            let configurationParam = state.configurations.find(c => c.configurationCode === configurationCode)
            if (configurationParam)
                return configurationParam.value;

            return null;
        },
        getConfigurationParam: (state, getters) => configurationCode => {
            let configurationParam = state.configurations.find(c => c.configurationCode === configurationCode)
            if (configurationParam)
                return configurationParam;

            return null;
        },
        getUserRoles: (state) => {
            return state.userRoles;
        }
    }
};